import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { calculateFinalRankings } from "./utils/rankingAlgorithm";
import { teams } from "./utils/tribeNames";

const AdminPage = () => {
  const [votingResults, setVotingResults] = useState([]);
  const [rosters, setRosters] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "submissions"));
        const results = querySnapshot.docs.map((doc) => doc.data());
        setVotingResults(results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchRosters = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "roster"));
        const rostersData = {};
        querySnapshot.forEach((doc) => {
          rostersData[doc.data().team] = doc.data().players;
        });
        setRosters(rostersData);
      } catch (error) {
        console.error("Error fetching rosters:", error);
      }
    };

    fetchData();
    fetchRosters();
  }, []);

  function deduplicateVotes(votingResults) {
    const uniqueVotesMap = new Map();

    for (const result of votingResults) {
      const key = result.name + result.team;
      if (!uniqueVotesMap.has(key)) {
        uniqueVotesMap.set(key, result);
      }
    }

    return Array.from(uniqueVotesMap.values());
  }

  const handleNukeTeamData = async (team) => {
    try {
      const q = query(collection(db, "submissions"), where("team", "==", team));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      console.log(`All documents for team ${team} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting documents:", error);
    }
  };

  return (
    <div>
      <h1>Admin Page</h1>
      <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
      {teams.map((team) => {
        const teamVotingResults = votingResults.filter(
          (result) => result.team === team
        );
        const votersList = teamVotingResults
          .map((result) => result.name)
          .join(", ");
        const deduplicatedResults = deduplicateVotes(teamVotingResults);
        const finalRankings = calculateFinalRankings(deduplicatedResults);
        const roster = rosters[team] || [];
        return (
          <div key={team}>
            <h2>{team} tribe</h2>
            <h3>Tribe Roster</h3>
            <p>{roster.join(", ")}</p>
            <h3>Tribal Council Results</h3>
            <p>
              Voted (total: {teamVotingResults.length}, considered:{" "}
              {deduplicatedResults.length}
              ): {votersList}
            </p>
            <h4>most votes:</h4>
            {finalRankings.map((group, rank) => (
              <div key={rank}>
                <p>
                  {rank + 1}:{group.length > 1 ? ` (${group.length})` : ""}{" "}
                  {group.join(", ")}
                </p>
              </div>
            ))}
            <button onClick={() => handleNukeTeamData(team)}>
              clear {team} voting data
            </button>
            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
          </div>
        );
      })}
    </div>
  );
};

export default AdminPage;
