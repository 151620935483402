import React, { useState } from "react";
import {
  getBallotValidity,
  getErrorMessage,
  BallotValidity,
} from "./utils/ballotValidation";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

const Voting = ({
  userName,
  team,
  players,
  submissionStatus,
  onSubmission,
}) => {
  const [ballot, setBallot] = useState(Array(players.length).fill(1));

  const handleChange = (index, value) => {
    const newValue = parseInt(value); // Parse the value as an integer
    // Check if the parsed value is a valid number
    const newBallot = [...ballot];
    newBallot[index] = newValue;
    setBallot(newBallot);
  };

  const handleSubmit = async () => {
    const error = getErrorMessage(ballot, players, submissionStatus);
    if (error) {
      alert(error);
      return;
    }
    // Prepare submission data
    const votesArray = ballot.map((index) => players[index - 1]);
    const submissionData = {
      name: String(userName),
      team: team,
      votes_array: votesArray,
    };

    // Submit data to Firestore
    try {
      // Add a new document with a generated ID to the "submissions" collection
      const docRef = await addDoc(
        collection(db, "submissions"),
        submissionData
      );
      console.log("Document written with ID: ", docRef.id);
      onSubmission();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div>
      <h2>Cast your vote for the {team} tribe</h2>
      <p>
        Rank your tribe members from 1 to {players.length}, where 1 is the
        member you MOST want to be voted OUT - and onto the jury.
      </p>

      {players.map((player, index) => (
        <li key={index}>
          <label>
            {player}:&nbsp;
            <input
              type="number"
              min="1"
              max={players.length}
              value={ballot[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              disabled={submissionStatus}
            />
          </label>
        </li>
      ))}

      <button
        disabled={
          getBallotValidity(ballot, players, submissionStatus) !==
          BallotValidity.VALID
        }
        onClick={handleSubmit}
      >
        Submit Ballot
      </button>
      <p style={{ color: "red" }}>
        {getErrorMessage(ballot, players, submissionStatus)}
      </p>
    </div>
  );
};

export default Voting;
