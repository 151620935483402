export const BallotValidity = {
  INCOMPLETE: 1,
  DUPLICATE_RANKINGS: 2,
  INVALID_RANKINGS: 3,
  ALREADY_SUBMITTED: 4,
  VALID: 5,
};

export const getBallotValidity = (ballot, players, submissionStatus) => {
  if (ballot.includes(0) || ballot.some(isNaN)) {
    return BallotValidity.INCOMPLETE;
  }
  if (new Set(ballot).size !== players.length) {
    return BallotValidity.DUPLICATE_RANKINGS;
  }
  if (
    ballot.some((value) => value < 1 || value > players.length || isNaN(value))
  ) {
    return BallotValidity.INVALID_RANKINGS;
  }
  if (submissionStatus === true) {
    return BallotValidity.ALREADY_SUBMITTED;
  }
  return BallotValidity.VALID;
};

export const getErrorMessage = (ballot, players, submissionStatus) => {
  const validity = getBallotValidity(ballot, players, submissionStatus);
  switch (validity) {
    case BallotValidity.INCOMPLETE:
      return "Please rank all players.";
    case BallotValidity.DUPLICATE_RANKINGS:
      return "Please ensure all rankings are unique.";
    case BallotValidity.INVALID_RANKINGS:
      return "Rankings must be between 1 and " + players.length;
    case BallotValidity.ALREADY_SUBMITTED:
      return "Thanks for voting! Your votes are locked in for this round. Please take a screenshot and send it to Riya!";
    default:
      return "";
  }
};
