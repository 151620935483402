import React from "react";
import AdminPage from "./components/AdminPage";
import HomePage from "./components/HomePage";
import "./survivor-theme.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
};
export default App;
