export const calculateFinalRankings = (votingResults) => {
  // Step 0: Logging initial voting results
  console.log("Step 0: Voting Results", votingResults);

  // Step 1: Extract each player's rankings
  const playerRankings = {};
  votingResults.forEach((result) => {
    result.votes_array.forEach((vote, index) => {
      const player = vote;
      const rank = index + 1;
      if (!playerRankings[player]) {
        playerRankings[player] = [];
      }
      playerRankings[player].push(rank);
    });
  });
  console.log("Step 1: Player Rankings", playerRankings);

  // Step 2: Aggregate the rankings for each player
  const aggregatedRankings = {};
  Object.keys(playerRankings).forEach((player) => {
    const rankings = playerRankings[player];
    const totalRank = rankings.reduce((acc, rank) => acc + rank, 0);
    const averageRank = totalRank / rankings.length;
    aggregatedRankings[player] = Math.round(averageRank * 100) / 100; // Round to nearest hundredth
  });
  console.log("Step 2: Aggregated Rankings", aggregatedRankings);

  // Step 3: Group players with the same rank
  const sortedRankings = {};
  Object.entries(aggregatedRankings).forEach(([player, rank]) => {
    if (!sortedRankings[rank]) {
      sortedRankings[rank] = [];
    }
    sortedRankings[rank].push(player);
  });
  console.log("Step 3: Sorted Rankings", sortedRankings);

  // Step 4: Sort the rankings by rank (ascending)
  const sortedKeys = Object.keys(sortedRankings).sort((a, b) => a - b);
  const finalRankings = sortedKeys.map((key) => sortedRankings[key]);
  console.log("Step 4: Final Rankings", finalRankings);

  return finalRankings;
};
