// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOy-mE1t4-zHmVWez2_kn1wM3hRFJVJJQ",
  authDomain: "survivor-voting-17bd0.firebaseapp.com",
  projectId: "survivor-voting-17bd0",
  storageBucket: "survivor-voting-17bd0.appspot.com",
  messagingSenderId: "135671980318",
  appId: "1:135671980318:web:3a592098811b20b9e3690f",
  measurementId: "G-7DTWTYSZVQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
