import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Voting from "./Voting";
import backgroundImage from "../assets/faded_background.png";

const HomePage = () => {
  const [teams, setTeams] = useState({}); // Track teams
  const [selectedTeam, setSelectedTeam] = useState("red");
  const [selectedName, setSelectedName] = useState(""); // Track user name
  const [submissionStatus, setSubmissionStatus] = useState(false); // Track submission status

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "roster"));
        const teamsData = {};
        querySnapshot.forEach((doc) => {
          teamsData[doc.data().team] = doc.data().players;
        });
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    // Initialize selectedName to the first name in the list of names for the selected team
    if (selectedTeam && teams[selectedTeam] && teams[selectedTeam].length > 0) {
      setSelectedName(teams[selectedTeam][0]);
    } else {
      setSelectedName(""); // If the list of names is empty, set selectedName to an empty string
    }
  }, [selectedTeam, teams]);

  const handleTeamChange = (event) => {
    if (!submissionStatus) {
      // Allow team change only if submission is not submitted
      setSelectedTeam(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    if (!submissionStatus) {
      // Allow team change only if submission is not submitted
      setSelectedName(event.target.value); // Update user name
    }
  };

  const handleSubmission = () => {
    setSubmissionStatus(true); // Update submission status
  };

  return (
    <div
      className="homePage"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0 /* Remove default body margin */,
        padding: 0 /* Remove default body padding */,
        height: "100vh" /* Set height to full viewport height */,
        width: "100vw" /* Set width to full viewport width */,
        display: "flex" /* Use flexbox to center content */,
        flexDirection: "column" /* Stack children vertically */,
        justifyContent: "center" /* Center content vertically */,
        alignItems: "center" /* Center content horizontally */,
      }}
    >
      <h1>Survivor: Island of David</h1>
      <h2>Welcome to Tribal Council</h2>
      <div>
        <label htmlFor="teamSelect">Select your tribe: </label>
        <select
          id="teamSelect"
          value={selectedTeam}
          onChange={handleTeamChange}
          disabled={submissionStatus}
        >
          {Object.keys(teams).map((team) => (
            <option key={team} value={team}>
              {team}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="nameSelect">Select your name: </label>
        <select
          id="nameSelect"
          value={selectedName}
          onChange={handleNameChange}
          disabled={submissionStatus}
        >
          {selectedTeam &&
            teams[selectedTeam] &&
            teams[selectedTeam].map((player) => (
              <option key={player} value={player}>
                {player}
              </option>
            ))}
        </select>
      </div>
      <p>If you select the wrong tribe or name, riya will be very sad.</p>
      <div>
        <Voting
          userName={selectedName}
          team={selectedTeam}
          players={teams[selectedTeam] ?? []}
          submissionStatus={submissionStatus}
          onSubmission={handleSubmission}
        />
      </div>
    </div>
  );
};

export default HomePage;
